import React, { Fragment } from 'react';
import Header from '../Utils/Header/Header';

const Contato  = () => {
    return (
        <Fragment>
            <Header activePage="'contato'"/>
        </Fragment>
    )

}
export default Contato;