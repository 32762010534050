import './AreaAdmin.css'
import React, { Fragment } from 'react';
import { Route, NavLink, Switch } from 'react-router-dom';
import Rotas from './AreaAdminRotas';
import BasePage from "../BasePage/BasePage";
import ListaEscola from "./Escola/lista-escola/ListaEscola"
import EscolaForm from './Escola/EscolaForm';
import ListaTurma from './Turma/lista-turma/ListaTurma';
import TurmaForm from './Turma/TurmaForm';
import ListaAluno from './Aluno/lista-aluno/ListaAluno';
import AlunoForm from './Aluno/AlunoForm';
import Login from './Login/Login';

class AreaAdmin extends BasePage {

    constructor(props) {
        super(props);

        this.stateInicial = {
            escola: undefined,
            turma: undefined,
            aluno: undefined
        };

        this.state = this.stateInicial;
    }

    aposLogin = () => {
        this.props.history.push(Rotas.ESCOLA_LISTA);
    }

    selecionarEscola = escola => {
        this.setState({escola: escola});
        this.props.history.push(Rotas.TURMA_LISTA);
    }

    selecionarTurma = turma => {
        this.setState({turma: turma});
        this.props.history.push(Rotas.ALUNO_LISTA);
    }

    editarEscola = (id) => {
        this.props.history.push(Rotas.ESCOLA_EDICAO.replace(':id', id));
    }

    editarTurma = (id) => {
        this.props.history.push(Rotas.TURMA_EDICAO.replace(':id', id));
    }

    editarAluno = (id) => {
        this.props.history.push(Rotas.ALUNO_EDICAO.replace(':id', id));
    }

    resetState = () => {
        this.setState(this.stateInicial);
    }

    resetTurma = () => {
        this.setState({
            turma: this.stateInicial.turma,
            aluno: this.stateInicial.aluno
        });
    }

    resetAluno = () => {
        this.setState({aluno: this.stateInicial.aluno});
    }

    handleUnauthorized = (error) => {
        if (error.status && error.status === 401) {
            this.props.history.push(Rotas.LOGIN);
        } else {
            throw error;
        }
    }

    renderPage() {
        const rotaAtual = this.props.location?.pathname;
        return (
            <Fragment>
                <div className="container">
                    {rotaAtual !== Rotas.LOGIN ?
                        <nav className="breadcrumb-nav">
                            <div className="nav-wrapper white mt-1">
                                <div className="center-align">
                                    <NavLink to={Rotas.ESCOLA_LISTA} onClick={this.resetState} className="breadcrumb">
                                        <i className="material-icons">account_balance</i>
                                        <span className="pl-2">
                                            { this.state.escola ? 
                                                this.state.escola.apelido ? this.state.escola.apelido
                                                : this.state.escola.nome
                                                : 'Escola'}
                                        </span>
                                    </NavLink>
                                    
                                    {this.state.escola ? 
                                        (
                                            <NavLink to={Rotas.TURMA_LISTA} onClick={this.resetTurma} className="breadcrumb">
                                                <i className="material-icons">menu_book</i>
                                                <span className="pl-2 text-truncate">
                                                    {this.state.turma ? this.state.turma.nome : 'Turma'}
                                                </span>
                                            </NavLink>
                                        ) : ''
                                    }

                                    {this.state.escola && this.state.turma ?
                                        (
                                            <NavLink to={Rotas.ALUNO_LISTA} onClick={this.resetAluno} className="breadcrumb">
                                                <i className="material-icons">school</i>
                                                <span className="pl-2 text-truncate">
                                                    {this.state.aluno ? this.state.aluno.nome : 'Aluno'}
                                                </span>
                                            </NavLink>
                                        ) : ''
                                    }
                                </div>
                            </div>
                        </nav>

                        : null
                    }
                    <div className="row mt-3">
                        <div className="col s12">
                            <Switch>
                                <Route path={Rotas.LOGIN} exact={true} render={routeProps => (
                                    <Login {...routeProps} aposLogin={this.aposLogin} />
                                )} />

                                <Route path={Rotas.ESCOLA_LISTA} exact={true} render={routeProps => (
                                    <ListaEscola {...routeProps}
                                        handleUnauthorized={this.handleUnauthorized}
                                        selecionar={this.selecionarEscola}
                                        editar={this.editarEscola}
                                    />
                                )} />
                                <Route path={Rotas.ESCOLA_EDICAO} exact={true} render={routeProps => (
                                    <EscolaForm {...routeProps}
                                        handleUnauthorized={this.handleUnauthorized}
                                    />
                                )} />
                                <Route path={Rotas.ESCOLA_NOVO} exact={true} render={routeProps => (
                                    <EscolaForm {...routeProps}
                                        handleUnauthorized={this.handleUnauthorized}
                                        selecionar={this.selecionarEscola}
                                    />
                                )} />


                                <Route path={Rotas.TURMA_LISTA} exact={true} render={routeProps => (
                                    <ListaTurma {...routeProps}
                                        handleUnauthorized={this.handleUnauthorized}
                                        escola={this.state.escola}
                                        selecionar={this.selecionarTurma}
                                        editar={this.editarTurma}
                                    />
                                )} />
                                <Route path={Rotas.TURMA_EDICAO} exact={true} render={routeProps => (
                                    <TurmaForm {...routeProps}
                                        handleUnauthorized={this.handleUnauthorized}
                                    />
                                )} />
                                <Route path={Rotas.TURMA_NOVO} exact={true} render={routeProps => (
                                    <TurmaForm {...routeProps}
                                        handleUnauthorized={this.handleUnauthorized}
                                        escola={this.state.escola}
                                        selecionar={this.selecionarTurma}
                                    />
                                )} />


                                <Route path={Rotas.ALUNO_LISTA} exact={true} render={routeProps => (
                                    <ListaAluno {...routeProps}
                                        handleUnauthorized={this.handleUnauthorized}
                                        turma={this.state.turma}
                                        editar={this.editarAluno}
                                    />
                                )} />
                                <Route path={Rotas.ALUNO_EDICAO} exact={true} render={routeProps => (
                                    <AlunoForm {...routeProps}
                                        handleUnauthorized={this.handleUnauthorized}
                                        turma={this.state.turma} />
                                )} />
                                <Route path={Rotas.ALUNO_NOVO} exact={true} render={routeProps => (
                                    <AlunoForm {...routeProps}
                                        handleUnauthorized={this.handleUnauthorized}
                                        turma={this.state.turma} />
                                )} />
                            </Switch>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default AreaAdmin;