import React, { Fragment } from 'react';
import Header from '../Utils/Header/Header';

const Sobre  = () => {
    return (
        <Fragment>
            <Header activePage="'sobre'"/>
        </Fragment>
    )

}
export default Sobre;