const AreaAdminRotas = {
    LOGIN: '/admin',

    ESCOLA_LISTA: '/admin/escola',
    ESCOLA_NOVO: '/admin/escola/novo',
    ESCOLA_EDICAO: '/admin/escola/:id/editar',

    TURMA_LISTA: '/admin/turma',
    TURMA_NOVO: '/admin/turma/novo',
    TURMA_EDICAO: '/admin/turma/:id/editar',

    ALUNO_LISTA: '/admin/aluno',
    ALUNO_NOVO: '/admin/aluno/novo',
    ALUNO_EDICAO: '/admin/aluno/:id/editar',
}
export default AreaAdminRotas;
